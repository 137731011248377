import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import { graphql } from "gatsby";
import { Col, Row } from "react-bootstrap";

import PageSection from "components/PageSection";
import RenderParagraph from "components/RenderParagraph";
import "./PrivacyDetails.scss";

export const fragment = graphql`
  fragment PrivacyMarkdownRemarkFragment on Frontmatter {
    anchor
    title
    subheader
    paragraphs {
      ...ParagraphFragment
    }
  }
`;

export const PARAGRAPH_IN_COLUMN = 9;

const PrivacyDetails = ({ className, frontmatter }) => {
  if (!frontmatter) return null;

  const { anchor, title, subheader, paragraphs } = frontmatter;

  return (
    <PageSection className={clsx("py-5", "bundle-section", className)} id={anchor}>
      <Row className="mt-5">
        <Col className="mt-5" />
      </Row>
      <Row>
        <Col className="bundle-texts text-sm-center text-md-left">
          <h1>{title}</h1>
          <h2>{subheader}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} className="bundle-texts text-sm-center text-md-left column-left">
          {paragraphs &&
            paragraphs
              .slice(0, PARAGRAPH_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
        <Col md={6} sm={12} className="bundle-texts text-sm-center text-md-left column-right">
          {paragraphs &&
            paragraphs
              .slice(PARAGRAPH_IN_COLUMN)
              .map((p) => <RenderParagraph p={p} key={uuidv4()} />)}
        </Col>
      </Row>
    </PageSection>
  );
};

PrivacyDetails.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.shape({
    anchor: PropTypes.string,
    title: PropTypes.string,
    subheader: PropTypes.string,
    paragraphs: PropTypes.arrayOf(
      PropTypes.shape({
        h1: PropTypes.string,
        h2: PropTypes.string,
        h3: PropTypes.string,
        h4: PropTypes.string,
        h5: PropTypes.string,
        h6: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }),
};

PrivacyDetails.defaultProps = {
  className: null,
  frontmatter: null,
};

export default PrivacyDetails;
