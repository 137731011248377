import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Footer, Navbar } from "views";
import PrivacyDetails from "views/Sections/PrivacyDetails";
import { AlternativeNavigation, SEO } from "components";

import { findNodeBy } from "utils/breakDownAllNodes";

import "utils/fixFontAwesome";
import "../style/main.scss";

export const query = graphql`
  query privacyQuery($langKey: String!) {
    site {
      meta: siteMetadata {
        keywords
        description
      }
    }
    content: allMarkdownRemark(filter: { fields: { langKey: { eq: $langKey } } }) {
      nodes {
        i18n: frontmatter {
          ...NavbarMarkdownRemarkFragment
          ...PrivacyMarkdownRemarkFragment
          ...FooterMarkdownRemarkFragment
          ...ContactUsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
    sitemap: allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey }, fileName: { regex: "/sitemap/i" } } }
    ) {
      nodes {
        i18n: frontmatter {
          ...LandingsNavigationMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Privacy = ({ data, pageContext }) => {
  const {
    site: {
      meta: { keywords, description },
    },
    content: { nodes },
  } = data;

  const navBarNode = findNodeBy(nodes, /navbar\./i);
  const footerNode = findNodeBy(nodes, /footer\./i);
  const privacyNode = findNodeBy(nodes, /privacy\./i, /pages/i);
  const contactUsNode = findNodeBy(nodes, /contactus\./i);
  const sitemapNode = data.sitemap.nodes[0];
  const seo = { lang: pageContext.langKey, keywords, description };

  return (
    <div>
      <SEO title="Privacy" {...seo} />
      <Navbar frontmatter={navBarNode.i18n} langKey={pageContext.langKey}>
        <AlternativeNavigation context={pageContext} frontmatter={sitemapNode.i18n} />
      </Navbar>
      <PrivacyDetails frontmatter={privacyNode.i18n} />
      <Footer
        frontmatter={footerNode.i18n}
        navigationNode={sitemapNode.i18n}
        contactUsNode={contactUsNode.i18n}
      />
    </div>
  );
};

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

Privacy.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Privacy;
